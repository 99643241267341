<script setup lang="ts">
import { decryptImgUrl } from '~/utils/crypto'

const props = defineProps({
  imgType: {
    type: Number,
    default: 1
  },
  src: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: '/video-dfbg.png'
  },
  width: {
    type: [String, Number],
    default: undefined
  },
  height: {
    type: [String, Number],
    default: undefined
  },
  quality: {
    type: [String, Number],
    default: undefined
  },
  sizes: {
    type: String,
    default: undefined
  },
  fit: {
    type: String,
    default: undefined
  },
  preset: {
    type: String,
    default: undefined
  },
  alt: {
    type: String,
    default: undefined
  },
  format: {
    type: String,
    default: undefined
  },
  loading: {
    type: String,
    default: 'lazy'
  },
  preload: {
    type: Boolean,
    default: undefined
  },
  decrypt: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(['load'])
const imgUrl = ref(props.placeholder)
watchEffect(async () => {
  if (process.client && props.decrypt && imgUrl.value) {
    imgUrl.value = await decryptImgUrl(props.src)
  }
})
function load(e: any) {
  emits('load', e)
}
</script>

<template>
  <NuxtImg
    :src="imgUrl"
    :placeholder="placeholder"
    :alt="alt"
    :width="width"
    :height="height"
    :quality="quality"
    :sizes="sizes"
    :fit="fit"
    :preset="preset"
    :format="format"
    :preload="preload"
    :loading="loading"
    @load="load"
  />
</template>

<style lang="less" scoped></style>
